// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');
@import url('https://fonts.googleapis.com/css?family=Josefin+Sans');


// Variables
@import 'variables';

// Vuetify
@import '~vuetify/dist/vuetify.min.css';

/*
 * Custom style
 */

[v-cloak] > * {
    display: none;
}
[v-cloak]::before {
    content: " ";
    position: absolute;
    top: calc(50% - 100px);
    left: calc(50% - 100px);
    display: block;
    width: 200px;
    height: 200px;
    background-image: url('/images/logos/logo.png');
}

.theme--light.application {
    background: white;
    color: rgba(0, 0, 0, .87);
}

a.brand, a.brand :hover {
    color: inherit;
    text-decoration: none;
}

.copy-link, .copy-link :hover {
  text-decoration: none;
    color: rgba(0, 0, 0, .54);
}

.v-toolbar--fixed {
  z-index: 10;
}

.v-navigation-drawer {
  z-index: 11;
}

.flag-icon {
  width: 20px;
}

.footer-copyright a {
  color: rgba(255, 255, 255, .6) !important;
}

.terms {
  padding: 0;
  margin: 0;
  box-shadow: none;
}
.terms div {
    padding: 0;
    margin: 0;
}

.blue--text {
    color: $mainBlue;
}

.main-blue {
    background-color: $mainBlue !important;
}

.v-btn.main-blue {
    background-color: $mainBlue !important;
    border-color: $mainBlue !important;
}

.main-blue-pagination > li > button.primary {
    background-color: $mainBlue !important;
    border-color: $mainBlue !important;
}

.footer-color {
    background-color: $footerColor !important;
}

.v-btn.footer-color {
    background-color: $footerColor !important;
}

.active-page {
  border-bottom: #4caf50 solid 2px !important;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.top-sharp {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

@media only screen and (min-width: 960px) {
  .px-md-4 {
    padding-left: $spacer * 1.5 !important;
    padding-right: $spacer * 1.5 !important;
  }
  .px-md-7 {
    padding-left: $spacer * 5 !important;
    padding-right: $spacer * 5 !important;
  }
}

.theme--light.v-btn.v-btn--disabled.active-page {
  color: #4caf50 !important;
  caret-color: #4caf50 !important;
}

.max-width-1140 {
  max-width: 1140px;
}

.card-second-fab {
  right: 65px;
}

.card-third-fab {
  right: 115px;
}

.home-container {
  width: 100%;
  max-width: none;
}

.manifesto-layout {
  padding: 1rem 3rem;
}

.manifesto-card {
  z-index: 3;
  border-radius: 0;
  text-align: justify;
  font-size: large;
}

.manifesto-card hr {
  border-width: 1px;
}

.manifesto-card p {
  margin-bottom: 0;
  line-height: 1.5em;
  font-family: "Josefin Sans", Roboto, sans-serif;
}

.lettrine:first-letter {
  font-size: 1.8em;
  font-weight: bold;
}

#banner {
  max-width: 700px;
}

.zero {
    background-color: white !important;
}

.zero-one {
    background-color: #3c77b1 !important;
}

.one-two {
    background-color: white !important;
}

.two-three {
    background-color: #edc113 !important;
}

.three-four {
    background-color: white !important;
}

.four-five {
    background-color: #52b359 !important;
}

.five-six {
    background-color: white !important;
}

.six-seven {
    background-color: #d86a34 !important;
}

.seven-eight {
    background-color: white !important;
}

.eight {
    background-color: #4986bd !important;
}

@media only screen and (min-width: 1200px) {
  .two-columns {
    columns: 2;
  }
}

#stats {
  font-size: 16px;
}
